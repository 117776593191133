//HEADINGS ---------------------------------------------------------
h1,
.h1 {
  font-family: Rakkas;
  font-style: normal;
  font-weight: normal;
  line-height: 100%;
  letter-spacing: 0.03em;
  font-feature-settings: "pnum" on, "onum" on;

  @include extra-small {
    @include calc-font-size(50, 100, 5.5, 7);
  }
}

h2,
.h2 {
  font-family: Rakkas;
  font-style: normal;
  font-weight: normal;
  //font-size: 64px;
  line-height: 120%;
  letter-spacing: 0.02em;
  font-feature-settings: "pnum" on, "lnum" on;

  @include extra-small {
    @include calc-font-size(50, 100, 3.5, 5);
  }
}

h3,
.h3 {
  font-family: Rakkas;
  font-style: normal;
  font-weight: normal;
  line-height: 120%;
  letter-spacing: 0.02em;
  font-feature-settings: "pnum" on, "lnum" on;

  @include extra-small {
    @include calc-font-size(50, 100, 2.8, 3.2);
  }
}

.large-text {
  font-family: Sen;
  font-style: normal;
  font-weight: bold;
  line-height: 130%;
  font-feature-settings: "tnum" on, "onum" on;

  @include extra-small {
    @include calc-font-size(50, 100, 1.9, 2.2);
  }
}

.medium-text {
  font-family: Sen;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 130%;
  letter-spacing: 0.01em;
  font-feature-settings: "tnum" on, "onum" on;
}

//TEXT ---------------------------------------------------------
.normal-text {
  font-family: Sen;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 130%;
  letter-spacing: -0.01em;
  font-feature-settings: "tnum" on, "onum" on;
}

.small-text {
  font-family: Sen;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  letter-spacing: -0.01em;
  font-feature-settings: "tnum" on, "onum" on;
}

//BUTTON TEXT ---------------------------------------------------------
.button-text {
  font-family: Sen;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 130%;
  letter-spacing: -0.02em;
  font-feature-settings: "tnum" on, "onum" on;
}

//TEXT FIELDS ---------------------------------------------------------
.label-text {
  @extend .normal-text;
}

.placeholder-text {
  @extend .small-text;
}

.error-text {
  font-family: Sen;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 130%;
  letter-spacing: 0.01em;
  font-feature-settings: "tnum" on, "onum" on;
  color: $orange;
}

//NAVBAR TEXT ---------------------------------------------------------
.navbar-title {
  font-family: Sen;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 130%;
  font-feature-settings: "tnum" on, "onum" on;
}

.navbar-title-active {
  font-family: Sen;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 130%;
  font-feature-settings: "tnum" on, "onum" on;
}

//typography for cards
.card-default-text {
  @extend .navbar-title;

  @include extra-small {
    @include calc-font-size(50, 100, 1.7, 1.9);
  }
}

.card-bold-text {
  @extend .navbar-title-active;

  @include extra-small {
    @include calc-font-size(50, 100, 1.7, 1.9);
  }
}

//TEXT SELECTION ---------------------------------------------------------
::-moz-selection {
  // for Firefox
  color: $dark-blue;
  background: $beige;
}

::selection {
  color: $dark-blue;
  background: $beige;
}
