main {
  overflow: hidden;

  .landing-page {
    margin-top: $landing-margin-top;

    .introduction-text {

      p,
      h2,
      .button-wrapper {
        text-align: center;
      }

      p {
        @extend .large-text;
        color: $light-green;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        margin-bottom: 40px;
      }

      .button-wrapper {
        margin-top: 70px;

        i {
          display: block;
          color: $dark-blue;
          margin-top: 50px;
          font-size: 3rem;
        }
      }

      .chevron {
        animation: bounce 0.5s infinite ease-in-out alternate;
      }
    }
  }

  .newest-products-section,
  .best-sellers-section {
    margin-top: 110px;
  }

  .best-sellers-section {
    margin-bottom: 140px;
  }

  .newsletter {
    margin-top: 130px;
    margin-bottom: 100px;

    h3 {
      text-align: center;
    }

    &-description {
      display: block;
      margin: 20px auto 0 auto;
      text-align: center;
    }

    &-input-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 45px;
    }

    &-input {
      width: 400px;
    }

    button {
      margin-top: 30px;
    }
  }

  .landing-leaves-wrapper {
    position: relative;

    .leaves-left-side,
    .leaves-right-side,
    .leaves-newsletter-left,
    .leaves-newsletter-right {
      position: absolute;
    }

    .leaves-newsletter-right {
      @include rotate(-180deg);
      transform: scaleX(-1);
      right: $leaves-landing-margin;
      bottom: calc($leaves-landing-margin / 2);
    }
  }
}

//SCREEN SIZES BIGGER THAN 1200px ---------------------------------------
@include extra-large {
  .product-cards-grid {
    @include grid-columns(repeat(3, 1fr), 50px, 100px);
    margin: 0 50px;
  }

  .leaves-left-side {
    left: 80px;
    top: -10px;
  }

  .leaves-right-side {
    right: 80px;
    top: 40px;
  }

  .leaves-newsletter-left {
    left: $leaves-landing-margin;
    bottom: calc($leaves-landing-margin / 2);
  }

  .newsletter-input-wrapper {
    margin-top: 45px;
  }
}

//SCREEN SIZES BIGGER THAN 992px AND SMALLER THAN 1200px -----------------
@include min-large-max-extra-large {
  .product-cards-grid {
    @include grid-columns(repeat(2, 1fr), 50px, 100px);
    margin: 0 50px;
  }

  .leaves-left-side {
    left: 80px;
    top: -50px;
  }

  .leaves-right-side {
    right: 80px;
    top: 10px;
  }

  .leaves-newsletter-left {
    left: $leaves-landing-margin;
    bottom: calc($leaves-landing-margin / 2);
  }
}

//SCREEN SIZES BETWEEN 768px AND 992px SCREEN SIZES ----------------------
@include min-medium-max-large {
  .product-cards-grid {
    @include grid-columns(500px, 0, 80px);
    margin: 0 100px;

    .card-product {
      margin-left: 30px;
      margin-right: 30px;
    }
  }

  .leaves-left-side {
    left: -20px;
    top: -50px;
  }

  .leaves-right-side {
    right: -40px;
    top: -10px;
  }

  .leaves-newsletter-left,
  .leaves-newsletter-right {
    display: none;
  }
}

//SCREEN SIZES SMALLER THAN 768px ----------------------------------------
@include max-medium {
  .product-cards-grid {
    @include grid-columns(1fr, 20px, 80px);
    margin: 0 20px;

    img {
      transform: scale(0.9);
      margin-left: -20px;
    }
  }

  .leaves-left-side {
    @include scale(0.5);
    left: -20px;
    top: 40px;
  }

  .leaves-right-side {
    @include scale(0.5);
    right: -40px;
    top: 80px;
  }

  .leaves-newsletter-left,
  .leaves-newsletter-right {
    display: none;
  }

  .newsletter {

    &-description,
    h3 {
      margin: 0 40px;
    }
  }

  .input-wrapper {
    .input {
      input {
        width: 250px;
      }
    }
  }
}

//SCREEN SIZES WITH 320px ----------------------------------------
@include max-extra-small {
  .product-cards-grid {
    img {
      transform: scale(0.8);
      margin-top: 20px;
    }
  }
}

@keyframes bounce {
  100% {
    transform: translate3d(0, 8px, 0);
  }
}