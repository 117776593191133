.sidebar {
  width: 240px;
  position: fixed;
  z-index: 1;
  background: $white;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
  border-radius: $radius-1;
  overflow-x: hidden;
  padding: $card-content-padding;

  a {
    @extend .navbar-title;
    color: $dark-blue;
    text-decoration: none;
    display: block;

    &:focus,
    &:hover {
      @extend .navbar-title-active;
    }
  }
}

//--------------------------------------------------------------------
//HORIZONTAL MENU WHEN SIDEBAR IS HIDDEN
.horizontal-menu {
  background-color: none;

  &-title {
    @extend .navbar-title;
    color: $dark-blue;
    width: 20%;
    text-align: center;
    padding-top: 15px;
    height: 55px;
    background-color: rgba($grey, 0.3);
    display: inline-block;
    vertical-align: middle;
  }

  .nav {
    width: 80%;
    height: 55px;
    background-color: $dark-blue;
    box-shadow: none;
    padding-left: 20px;
    display: inline-block;
    vertical-align: middle;

    &-item {
      @extend .medium-text;
      color: $white;
      padding: 15px;
      display: inline-block;
      vertical-align: middle;

      &:not(:last-child) {
        border-right: 1px solid rgba($grey, 0.2);
      }

      &:hover {
        color: $light-green;
      }
    }
  }
}

.scroll {
  white-space: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  &::-webkit-scrollbar {
    display: none;
  }
}

@include max-large {
  .horizontal-menu {
    &-title {
      width: 30%;
    }
    .nav {
      width: 70%;
    }
  }
}

@include max-medium {
  .horizontal-menu {
    &-title {
      width: 40%;
      padding-right: 20px;
      padding-left: 20px;
    }

    .nav {
      width: 60%;
    }
  }
}

//EACH FUNCTION TO CHANGE PRODUCT NAME COLOR (SIDEBAR) WHEN HOVER/FOCUS ------------------------------
$product-colors: (
  fig: $light-green,
  blueberry: $light-blue,
);

@each $product, $color in $product-colors {
  .#{$product}-sidebar-link {
    &:hover .sidebar-product-name,
    &:focus .sidebar-product-name {
      color: $color;
    }
  }
}
