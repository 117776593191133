//PADDINGS FOR THE PAGE ITSELF ------------------------------
.product-list-page {
  margin-left: $global-margin-sides;
  margin-right: $global-margin-sides;
  padding-top: $global-margin-top;
}

@include max-small {
  .product-list-page {
    margin-left: $global-margin-sides - 10;
    margin-right: $global-margin-sides - 10;
  }
}

//-----------------------------------------------------------
//TITLE & SUBTITLE SECTION ----------------------------------
.title-subtitle {
  width: 48%;
}

@include max-medium {
  .title-subtitle {
    width: 90%;
  }
}

//-----------------------------------------------------------
//MAIN GRID FOR PRODUCTS
.product {
  &-list-grid {
    @include grid-columns(repeat(auto-fill, minmax(340px, 1fr)));
    justify-items: center;
    grid-gap: 80px 25px;
    margin-bottom: 150px;
    margin-top: 30px;
  }

  &-image {
    width: 80%;
    height: auto;
    margin-bottom: 10px;
    @include scale(1.1);
    @include transition(0.3s);

    &:hover {
      transform: scale(1.2);
    }
  }

  &-name {
    text-align: center;
    color: $dark-blue;
  }

  .btn {
    display: block;
    margin: 20px auto;
    width: 50%;
  }
}

//-----------------------------------------------------------

//EACH FUNCTION FOR HOVER/FOCUS BEHAVIORS ------------------------------
//the array "$product-colors" is inside _variables.scss
@each $product, $color in $product-colors {
  .#{$product}-link {
    &:hover .product-name,
    &:focus .product-name {
      color: $color;
    }
  }
}
