//GENERAL STYLES ----------------------------------------------------------
.about-page {
  overflow: hidden;

  .header-wrapper {
    .title,
    .large-text {
      text-align: center;
      margin: 0 auto;
    }

    .large-text {
      margin-top: 10px;
    }
  }

  .about-section {
    padding: 50px;

    p:last-child {
      margin-top: 20px;
    }
  }

  .our-story {
    z-index: 1;
  }

  .cards-our-brands-wrapper {
    img {
      display: block;
      margin: 0 auto;
    }
  }

  .discover-our-brands {
    h3 {
      margin-bottom: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .cards-our-brands-wrapper {
    margin: 0 40px;
  }

  .cards-about-wrapper,
  .discover-our-brands {
    margin-bottom: 150px;
  }

  .leaves-wrapper {
    position: relative;
  }

  .about-left-leaves,
  .about-right-leaves {
    position: absolute;
  }

  .about-left-leaves {
    z-index: -1;
  }

  .about-right-leaves {
    @include rotate(-180deg);
  }
}

//SCREEN SIZES BIGGER THAN 1200px -----------------------------------------
@include extra-large {
  .header-wrapper {
    padding-top: 150px;

    .title,
    .large-text {
      width: 55%;
    }

    .large-text {
      padding-bottom: 200px;
    }
  }

  .about-section {
    width: 50vw;
    margin-bottom: 100px;
  }

  .about-wrapper {
    display: flex;
    flex-direction: row;

    .our-story {
      margin-top: 100px;
    }
  }

  .about-left-leaves {
    bottom: -30px;
    left: 40px;
  }

  .about-right-leaves {
    top: 300px;
    right: 40px;
  }

  .cards-our-brands-wrapper {
    @include grid-columns(1fr, 50px);
  }

  .logo-card {
    height: 190px;
  }
}

//SCREEN SIZES BIGGER THAN 992px AND SMALLER THAN 1200px -------------------------------
@include min-large-max-extra-large {
  .header-wrapper {
    padding-top: 150px;

    .title,
    .large-text {
      width: 55%;
    }

    .large-text {
      padding-bottom: 100px;
    }
  }

  .about-section {
    width: 50vw;
    margin-bottom: 100px;
  }

  .about-wrapper {
    display: flex;
    flex-direction: row;

    .our-story {
      margin-top: 100px;
    }
  }

  .about-left-leaves {
    top: 70px;
    left: 40px;
  }

  .about-right-leaves {
    top: 850px;
    right: 40px;
  }

  .cards-our-brands-wrapper {
    @include grid-columns(1fr, 50px);
  }

  .logo-card {
    padding: 20px;
  }
}

//SCREEN SIZES BETWEEN 768px AND 992px SCREEN SIZES ----------------------
@include min-medium-max-large {
  .header-wrapper {
    padding-top: 150px;

    .large-text {
      padding-bottom: 100px;
    }
  }

  .about-section {
    width: 100%;
    margin-bottom: 30px;
  }

  .about-wrapper {
    display: flex;
    flex-direction: column;
  }

  .about-left-leaves {
    top: 150px;
    left: 40px;
  }

  .about-right-leaves {
    top: 950px;
    right: 40px;
  }

  .cards-our-brands-wrapper {
    display: flex;
    flex-direction: column;
  }

  .cards-our-brands-wrapper {
    @include grid-columns(1fr, 50px, 50px);
    margin-left: 50px;
    margin-right: 50px;
  }

  .logo-card {
    height: 200px;

    .physalina-logo {
      width: 70%;
    }
  }
}

//SCREEN SIZES SMALLER THAN 768px -----------------------------------------
@include max-medium {
  .header-wrapper {
    padding-top: 150px;

    .title,
    .large-text {
      width: 70%;
    }

    .large-text {
      padding-bottom: 50px;
    }
  }

  .about-left-leaves {
    top: 170px;
    left: -30px;
  }

  .about-right-leaves {
    display: none;
  }

  .cards-about-wrapper,
  .cards-our-brands-wrapper {
    @include grid-columns(1fr, 0px, 30px);
    margin-left: 30px;
    margin-right: 30px;
  }

  .cards-about-wrapper {
    margin-bottom: 80px;
  }

  .logo-card {
    img {
      width: 90%;
    }
  }

  .discover-our-brands {
    h3 {
      width: 50%;
      display: block;
      margin: 0 auto;
      text-align: center;
    }
  }
}

//SCREEN SIZES SMALLER THAN 576px -----------------------------------------
@include max-extra-small {
  .cards-our-brands-wrapper {
    @include grid-columns(1fr, 0, 30px);
  }
}
