.flavours-container {
  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    background-color: $beige;
  }

  .flavours-content {
    display: flex;
    justify-content: center;
    align-items: baseline;

    .flavours-wrapper {
      display: flex;
      flex-direction: row;
      width: 85%;

      .image-wrapper {
        width: 100%;
        height: 100%;
        max-height: 400px;
        max-width: 400px;
      }

      .flavour-name-wrapper {
        position: relative;

        svg {
          position: absolute;
          top: -20%;
        }

        h3 {
          margin-left: 50px;
          text-align: center;
        }
      }

      .fig-flavour,
      .blueberry-flavour {
        align-items: center;
        display: flex;
        flex-direction: column;

        width: 50%;

        img {
          width: 100%;
          object-fit: contain;
        }
      }

      .fig-flavour {
        margin-right: 10px;
        margin-left: 40px;
      }

      .blueberry-flavour {
        margin-right: 40px;
      }
    }
  }
}

@include large {
  .flavours-container {
    margin-bottom: 200px;
  }
}

@include medium {
  .flavours-container {
    .header {
      height: 200px;
    }

    .flavours-content {
      margin-top: -70px;
    }
  }
}

@include max-medium {
  .flavours-container {
    margin-bottom: 100px;
  }
}

@include max-small {
  .flavours-container {
    margin-bottom: 80px;

    .header {
      height: 150px;
    }

    .flavours-content {
      margin-top: -70px;

      .flavours-wrapper {
        flex-direction: column;
        align-items: center;

        .flavour-name-wrapper {
          margin-left: -50px;
        }
      }

      .fig-flavour {
        margin-bottom: 30px;
      }

      .blueberry-flavour {
        margin-left: 50px;
      }
    }
  }
}

@include max-extra-small {
  .flavours-container {
    .header {
      height: 100px;
      text-align: center;
    }

    .flavours-content {
      margin-top: -10px;
    }
  }
}
