.terms-conditions-wrapper {
  width: 60%;
  padding-top: 150px;
  margin-left: 60px;

  .terms-conditions {
    h3 {
      color: $dark-blue;
      margin-bottom: 5px;
      margin-top: 20px;
    }

    section {
      margin-bottom: 30px;
    }
  }
}
