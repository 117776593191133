// TOPNAV ----------------------------------------------------
nav {
  @extend .navbar-title;
  width: 100vw;
  background: rgba($white, 0.9);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  height: 70px;
  z-index: 5;

  .logo-nav {
    float: left;
    padding-top: 20px;
    padding-right: calc($global-margin-sides / 2);

    svg {
      &:hover {
        @include transition(0.3s);
        fill: $light-green;
      }
    }
  }

  ul {
    li {
      @extend .padding20-20;
      display: inline-block;
      margin: 0 5px;
      color: $dark-blue;
    }

    a {
      text-decoration: none;
      text-align: center;
      color: $dark-blue;

      &:hover {
        @extend .navbar-title-active;
        color: $light-green;
      }

      &:focus {
        @extend .navbar-title-active;
        color: $light-green;
        outline: none;
      }

      &.active {
        color: $light-green;
      }
    }
  }

  .checkbtn {
    font-size: 3.5rem;
    color: $dark-blue;
    float: right;
    line-height: 190%;
    margin-right: 40px;
    cursor: pointer;
    display: none;
  }

  #check {
    display: none;
  }

  .search-container {
    float: right;
    padding-top: 7px;
    padding-right: 50px;

    button {
      padding: 16px 10px;
      margin-left: 3px;
      font-size: 18px;
      border: none;
      background: none;
      cursor: pointer;
    }
  }
}

@include large {
  .logo-nav {
    margin-left: 20px;
  }
}

@include max-large {
  nav {
    .checkbtn {
      display: block;
    }

    ul {
      @include transition(0.3s);
      position: fixed;
      width: 100%;
      height: 100vh;
      top: 70px;
      left: -100%;
      background-color: $white;

      li {
        font-family: Sen;
        font-style: normal;
        font-size: 2rem;
        line-height: 130%;
        font-feature-settings: "tnum" on, "onum" on;
        text-align: left;
        padding-left: 60px;
        color: $dark-blue;
        display: block;
      }
    }

    #check:checked~ul {
      left: 0;
    }

    .search-container {
      float: left;
      padding-left: 60px;
    }
  }
}

@include extra-small {
  .logo-nav {
    padding-left: 40px;
  }
}

@include max-extra-small {
  .logo-nav {
    svg {
      width: 160px;
    }
  }
}