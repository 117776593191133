//CARD BASE FOR EVERY CARDS USED --------------------------------------
.card {
  width: auto;
  padding-bottom: 30px;
  left: 0;
  top: 0;
  bottom: 0;
  border-radius: $radius-3;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);

  //titles for every card
  .title {
    @extend .large-text;
    color: $dark-blue;
    padding: $card-content-padding 0 0 $card-content-padding;
  }
}

//CARD INGREDIENTS ---------------------------------------
.card-ingredients {
  @extend .card;
  @include blur(1px);
  background: rgba($white, 0.7);
  padding-bottom: 50px;

  .ingredients-content {
    padding: 0 0 0 $card-content-padding;

    p {
      margin-bottom: 3px;
    }
  }
}

//CARD NUTRITIONAL VALUES ---------------------------------------
.card-nutritional {
  @extend .card;
  @include blur(1px);
  background: rgba($white, 0.7);

  .nutritional-content {
    padding: 0 0 0 $card-content-padding;

    .energy,
    .lipids,
    .hydrates,
    .fiber,
    .proteins,
    .salt {
      @extend .card-default-text;
      color: $dark-blue;
      margin-bottom: 3px;
    }

    span:nth-child(1) {
      @extend .card-bold-text;
    }

    .lipids,
    .hydrates {
      span:nth-child(3) {
        @extend .medium-text;
      }
    }
  }
}

//CARD BRAND LOGOTYPES ------------------------------------------------
.logo-card {
  @extend .card;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: $card-content-padding;
  @include transition(0.1s);

  &:hover {
    cursor: pointer;
    transform: scale(1.03);
    @include transition(0.2s);
  }
}

//CARD DISABLED ------------------------------------------------------
.card-disabled {
  @extend .card;
  color: $grey;
  background-color: rgba($color: $grey, $alpha: 0.2);
  box-shadow: none;
}

//CARD FOR PRODUCTS ON LANDING PAGE -----------------------------------
.card-product {
  background: $white;
  box-shadow: 0px 4px 30px rgba($light-green, 0.2);
  border-radius: $radius-3;
  padding: $card-content-padding $card-content-padding 10px;
  margin-top: -50px;

  &:hover {
    cursor: pointer;
    box-shadow: 0px 4px 30px rgba($light-green, 0.3);
    @include transition(0.3s);

    .card-product-name {
      margin-left: 15px;
      @include transition(0.2s);
    }
  }

  .card-product-layout {
    display: flex;
    flex-direction: row;
  }

  .card-product-name {
    margin-bottom: 20px;
    @include transition(0.3s);
  }

  .card-product-image {
    margin-right: 20px;

    .resized-product-image {
      width: 130px;
    }
  }
}
