//BREADCRUMB -------------------------------------------------
ul.breadcrumb {
  @extend .small-text;
  color: $light-green;
  list-style: none;

  li {
    display: inline;

    a {
      color: $dark-blue;
      text-decoration: none;

      &:hover {
        color: $light-green;
      }
    }
  }

  //bar before each item
  li + li:before {
    padding: 4px;
    color: $dark-blue;
    content: "/\00a0";
  }
}
