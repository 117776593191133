.search-results-page,
.no-results-page {
  padding-top: $global-margin-top;

  a {
    @include transition(0.3s);

    &:hover {
      @include transition(0.3s);
      margin-left: -10px;
      cursor: pointer;
    }

    i {
      color: $dark-blue;
    }
  }
}

//no results styling
.no-results-wrapper {
  background: $white;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.12);
  border-radius: $radius-10;
  padding: $card-content-padding;
  display: block;
  margin: 150px auto;
}

//MEDIA QUERIES -------------------------------------
@include large {

  .search-results-page,
  .no-results-page {
    margin-left: $global-margin-sides;
    margin-right: $global-margin-sides;
  }

  .no-results-wrapper {
    width: 40%;
  }
}

@include max-large {

  .search-results-page,
  .no-results-page {
    margin-left: $global-margin-sides;
    margin-right: $global-margin-sides;
  }
}

@include max-small {

  .search-results-page,
  .no-results-page {
    margin-left: calc($global-margin-sides / 2);
    margin-right: calc($global-margin-sides / 2);
  }
}