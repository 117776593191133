@include extra-large {
  .headings-wrapper-contacts {
    margin: 0 0 30px 60px;
  }

  .contacts-grid {
    display: flex;
    flex-direction: row;
    margin-bottom: 150px;

    .brand-contacts {
      animation-name: move-contacts;
      animation-duration: 2s;
      animation-fill-mode: forwards;
    }

    .contact-card {
      margin-left: 50px;
      width: 500px;
    }

    .textarea-size {
      height: 150px;
    }
  }

  .leaves-contacts {
    left: 55%;
    bottom: -30px;
  }
}

//SCREEN SIZES BIGGER THAN 992px AND SMALLER THAN 1200px -------------------------------
@include min-large-max-extra-large {
  .headings-wrapper-contacts {
    margin: 0 0 30px 60px;
  }

  .contacts-grid {
    display: flex;
    flex-direction: row;
    margin-bottom: 150px;

    .contact-card {
      margin: 0 50px 0 100px;
      width: 700px;
    }

    .textarea-size {
      height: 150px;
    }
  }

  .leaves-contacts {
    left: 40%;
    bottom: -30px;
  }
}

//SCREEN SIZES BETWEEN 768px AND 992px SCREEN SIZES -----------------------------------------
@include min-medium-max-large {
  .headings-wrapper-contacts {
    margin-left: 60px;
  }

  .contacts-grid {
    display: flex;
    flex-direction: column;

    .single-contact-component {
      margin-left: 60px;
    }

    .contact-card {
      margin: 60px 220px 100px 50px;
      width: 430px;
    }

    .textarea-size {
      height: 150px;
    }
  }

  .leaves-contacts {
    right: 10%;
    bottom: -30px;
  }
}

//SCREEN SIZES SMALLER THAN 768px -----------------------------------------
@include max-medium {
  .headings-wrapper-contacts {
    margin-left: 50px;
  }

  .headings-wrapper-contacts,
  .brand-contacts {
    margin-right: 50px;
  }

  .contact-card {
    width: 350px;
    margin: 0 0 50px 30px;
  }

  .textarea-size {
    height: 100px;
  }

  .leaves-contacts {
    right: -15%;
    bottom: -30px;
  }
}

//SCREEN SIZES SMALLER THAN 320px -----------------------------------------
@include max-extra-small {
  .headings-wrapper-contacts,
  .brand-contacts {
    margin-right: 30px;
  }

  .contacts-grid {
    display: flex;
    flex-direction: column;

    .contact-card {
      margin: 70px 30px 50px;
      width: 280px;
    }

    .textarea-size {
      height: 100px;
    }
  }
}

//GENERAL STYLES FOR ALL SCREEN SIZES -------------------------------------
.contacts-page {
  overflow: hidden;
}

.headings-wrapper-contacts {
  padding-top: 120px;
}

.contacts-grid {
  margin-bottom: 200px;
}

//class from contact.js component
.single-contact-component {
  width: 80%;
  margin: 50px 0 0 50px;

  .contact-icon {
    @include transition(0.2s);
    font-size: 5rem;
    color: $dark-blue;
    margin-top: 10px;

    &:hover {
      color: $light-blue;
      cursor: pointer;
    }
  }
}

//card with textfields to submit message
.contact-card {
  @include blur(2px);
  background: rgba($white, 0.5);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: $radius-10;
  padding: $card-content-padding;
  margin-top: 50px;
  margin-right: 80px;

  &-item,
  &-button {
    margin-top: 20px;
  }

  &-button {
    .btn--large {
      width: 100%;
    }
  }

  .input-width,
  .textarea-size {
    width: 100%;
  }
}

.leaves-contacts-wrapper {
  position: relative;

  .leaves-contacts {
    position: absolute;
    z-index: -1;
  }
}

@keyframes move-contacts {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(80px);
    animation-play-state: paused;
  }
}
