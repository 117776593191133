.dropdown-wrapper {
  position: relative;
  padding: 3px;

  .filter-input {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    width: 180px;
    padding: 0 15px;
    border: 1px solid $grey;
    border-radius: $radius-1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:focus {
      @include transition(0.1s);
      border: 1px solid $light-green;
      box-shadow: 0px 0px 4px $light-green;
      border-radius: $radius-1;
      outline: none;
    }

    &:hover {
      cursor: pointer;
    }

    i {
      padding-bottom: 3px;
    }
  }

  .filter-input-content {
    position: absolute;
    width: 180px;
    padding: 0 15px;
    margin-top: 5px;
    border: 1px solid $grey;
    border-radius: $radius-1;
    background-color: $white;
    z-index: 3;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

@include large {
  .dropdown-wrapper {
    float: right;
  }
}

@include max-large {
  .dropdown-wrapper {
    margin-top: 30px;
  }
}

@include max-small {
  .dropdown-wrapper {
    margin-bottom: 60px;
  }
}
