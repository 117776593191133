//PADDINGS ------------------------------
.padding10-10 {
  padding: 10px;
}

.padding20-20 {
  padding: 20px;
}

.padding30-30 {
  padding: 30px;
}

.padding-bottom20 {
  padding-bottom: 20px;
}

.padding-bottom30 {
  padding-bottom: 30px;
}

.padding-top20 {
  padding-top: 20px;
}

.padding-top30 {
  padding-top: 30px;
}

.padding-top40 {
  padding-top: 40px;
}

.padding-top70 {
  padding-top: 70px;
}

.padding-left10 {
  padding-left: 10px;
}

.padding-left20 {
  padding-left: 20px;
}

.padding-left30 {
  padding-left: 30px;
}

.padding-left50 {
  padding-left: 50px;
}

.padding-right30 {
  padding-right: 30px;
}

.padding-right50 {
  padding-right: 50px;
}

//MARGINS
//------------------------------------------------------
//TOP
.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-90 {
  margin-top: 90px;
}

.mt-100 {
  margin-top: 100px;
}

//BOTTOM
.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-90 {
  margin-bottom: 90px;
}

.mb-100 {
  margin-bottom: 100px;
}

//RIGHT
.mr-10 {
  margin-right: 10px;
}

.mr-20 {
  margin-right: 20px;
}

//LEFT
.ml-10 {
  margin-left: 10px;
}

.ml-20 {
  margin-left: 20px;
}

//TEXT COLORS ------------------------------
.dark-blue-text {
  color: $dark-blue;
}

.light-blue-text {
  color: $light-blue;
}

.orange-text {
  color: $orange;
}

.white-text {
  color: $white;
}

.grey-text {
  color: $grey;
}

.dark-green-text {
  color: $dark-green;
}

.light-green-text {
  color: $light-green;
}

//BACKGROUND COLORS ------------------------------
.background-light-green {
  background-color: $light-green;
}

.background-light-blue {
  background-color: $light-blue;
}

.background-orange {
  background-color: $orange;
}

.background-red {
  background-color: $red;
}

.background-dark-green {
  background-color: $dark-green;
}

.background-dark-blue {
  background-color: $dark-blue;
}

//POSITION ----------------------------------------
.position-fixed {
  position: fixed;
}
