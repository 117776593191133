::-webkit-scrollbar {
  width: 8px;

  &-track {
    background: #eaeff0;
  }

  &-thumb {
    background: $dark-blue;
  }
}
