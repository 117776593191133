//COLORS ------------------------------------------------------
$light-green: #2ed065;
$dark-green: #008e3d;
$dark-blue: #0f2043;
$beige: #f3eee3;
$grey: #b9b9b9;
$white: #ffffff;
$orange: #ff8800;
$light-blue: #0093d5;
$red: #ef1e43;

//BORDER-RADIUS ------------------------------------------------
$radius-1: 1px;
$radius-3: 3px;
$radius-5: 5px;
$radius-10: 10px;
$radius-15: 15px;
$radius-20: 20px;

//MEASURES -----------------------------------------------------
$card-content-padding: 30px;
$card-about-padding: 40px;

//SCREEN SIZES --------------------------------------------------
$screen-extra-small: 320px;
$screen-small: 576px;
$screen-medium: 765px;
$screen-large: 992px;
$screen-extra-large: 1200px;

//SVG PATTERN SIZES ---------------------------------------------
$pattern-size-small: 100px;
$pattern-size-medium: 150px;
$pattern-size-large: 200px;
$pattern-size-extra-large: 400px;

//MARGINS FOR SCREENS -------------------------------------------
$global-margin-top: 120px;
$global-margin-sides: 60px;
$landing-margin-top: 200px;

$leaves-landing-margin: -400px;

//ARRAY $product-colors for changing color when hovering product
//this is part of the each function inside file _productList.scss
$product-colors: (
  fig: $light-green,
  blueberry: $light-blue,
);
