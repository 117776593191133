.searchbar-input {
  position: relative;
}

.searchbar-icon {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: $dark-blue;

  &:hover {
    color: $dark-green;
  }
}
