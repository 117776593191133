//GRID FOR DISCOVER PRODUCTS' SECTION
//this grid is also applied to suggested products inside each product details page
.discover {
  &-product {
    &:hover {
      .discover-product-image {
        opacity: 0.3;
        @include transition(0.3s);
      }
    }
  }

  &-section-grid {
    @include grid-columns(repeat(auto-fill, minmax(200px, 300px)));
    justify-items: center;
    margin-top: -60px;
    margin-bottom: 100px;
  }

  &-product-image {
    width: 80%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    @include transition(0.3s);
  }

  &-product-name {
    @extend .medium-text;
    display: block;
    margin: 0 auto;
    text-align: center;
    color: $dark-blue;
    width: 55%;
  }
}

@include max-large {
  .discover-section-grid {
    grid-template-columns: 1fr 1fr;
  }
}
