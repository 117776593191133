.explore-section {
  background-color: $dark-blue;
  height: 130px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  i {
    @include calc-font-size(50, 100, 1.7, 1.9);
    padding-left: 15px;
    color: $white;
  }
}

@include large {
  .explore-section {
    .link {
      text-decoration: none;
      @include transition(0.9s);

      &:hover {
        margin-left: 20px;
        @include transition(0.3s);

        i,
        .h3 {
          color: $grey;
          @include transition(0.3s);
        }
      }
    }
  }
}

@include max-small {
  .explore-section {
    height: 90px;
  }
}

@include max-extra-small {
  .explore-section {
    height: 150px;

    a {
      width: 80%;
    }
  }
}
