//SCREEN SIZES BIGGER THAN 992px -----------------------------------------------
@include extra-large {
  .breadcrumb-container {
    margin-left: 60px;
    padding-top: 120px;
  }

  .horizontal-menu {
    display: none;
  }

  .product-details-grid {
    @include grid-columns(1fr, 30px);
    grid-template-areas:
      "sidebar     left                  right"
      "sidebar     details               details"
      "sidebar     suggestions-heading   suggestions-heading"
      "sidebar     suggested-products    suggested-products";

    .sidebar-menu {
      grid-area: sidebar;
    }

    .left-content-container {
      grid-area: left;
      margin-top: 30px;
      margin-bottom: 50px;
    }

    .right-content-container {
      grid-area: right;
      width: 490px;
      margin-bottom: 50px;
      padding-right: 50px;
    }

    .details {
      grid-area: details;
    }

    .cards {
      display: flex;
      flex-direction: row;
      margin-top: 20px;
    }

    .card-ingridients-container {
      width: 520px;
      padding-right: 20px;
      grid-area: card-ingridients-container;
    }

    .card-nutritional-container {
      padding-left: 20px;
      width: 540px;
      grid-area: card-nutritional-container;
    }

    .suggestions-heading {
      grid-area: suggestions-heading;
      margin-left: 10px;
      margin-top: 100px;
    }

    //suggested product images
    //the grid for these images is styled in blocs/_discoverProducts.scss
    .discover-section-grid {
      grid-area: suggested-products;
    }
  }
}

//SCREEN SIZES BIGGER THAN 992px AND SMALLER THAN 1200px ----------------------------------------------
@include min-large-max-extra-large {
  .horizontal-menu {
    display: none;
  }

  .breadcrumb-container {
    margin-left: 60px;
    padding-top: 120px;
  }

  .product-details-grid {
    @include grid-columns(140px, 30px);
    grid-template-areas:
      "sidebar     left"
      "sidebar     right"
      "sidebar     details"
      "sidebar     suggestions-heading"
      "sidebar     suggested-products";

    .sidebar-menu {
      grid-area: sidebar;
    }

    .left-content-container {
      grid-area: left;
      margin-top: 30px;
      margin-bottom: 50px;
    }

    .right-content-container {
      grid-area: right;
      width: 550px;
      margin-bottom: 50px;
      padding-right: 50px;
    }

    .details {
      grid-area: details;
      margin-left: 160px;
    }

    .cards {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
    }

    .card-ingridients-container {
      grid-area: card-ingridients-container;
      width: 550px;
      margin-bottom: 40px;
    }

    .card-nutritional-container {
      grid-area: card-nutritional-container;
      width: 550px;
    }

    .suggestions-heading {
      grid-area: suggestions-heading;
      margin-left: 180px;
      margin-top: 70px;
    }

    //suggested product images
    //the grid for these images is styled in blocs/_discoverProducts.scss
    .discover-section-grid {
      grid-area: suggested-products;
      margin-left: 90px;
    }
  }
}

//SCREEN SIZES BIGGER THAN 768px AND SMALLER THAN 992px ----------------------------------------------
@include min-medium-max-large {
  .sidebar-menu {
    display: none;
  }

  .product-details-grid {
    padding-top: 20px;
    grid-template-areas:
      "sidebar    left"
      "sidebar    right"
      "sidebar    card-ingridients-container"
      "sidebar    card-nutritional-container"
      "sidebar    suggestions-heading"
      "sidebar    suggested-products";
  }

  .cards {
    display: flex;
    flex-direction: column;
  }
}

.right-content-container {
  p {
    color: $dark-blue;
  }
}

//SCREEN SIZES SMALLER THAN 768px -----------------------------------------------
@include max-medium {
  .breadcrumb-container {
    padding-top: 60px;
    margin-left: 30px;
  }

  .product-details-grid {
    grid-template-areas:
      "left"
      "right"
      "details"
      "card-ingridients-container"
      "card-nutritional-container"
      "suggestions-heading"
      "suggested-products";

    .left-content-container,
    .right-content-container,
    .card-ingridients-container,
    .card-nutritional-container,
    .suggestions-heading,
    .discover-section-grid {
      padding-left: 30px;
      padding-right: 30px;
    }

    .suggestions-heading {
      margin-top: 50px;
    }

    .right-content-container,
    .card-ingridients-container,
    .card-nutritional-container {
      padding-bottom: 40px;
    }

    .details {
      h2 {
        margin-left: 30px;
        margin-bottom: 30px;
      }
    }
    .card-ingridients-container {
      margin-top: -20px;
    }

    .leaves-wrapper {
      display: none;
    }
  }
}

//SCREEN SIZES SMALLER THAN 576px -----------------------------------------------
@include max-small {
  .sidebar-menu {
    display: none;
  }

  .breadcrumb-container {
    padding-left: 30px;
  }

  .left-content-container,
  .product-detail-image {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

//display brand's logos when hovering product images
$product-logos: (
  physalina: url("../../images/logos/physalina-logo.svg"),
  floryptus: url("../../images/logos/floryptus-logo.svg"),
  qpetisco: url("../../images/logos/qpetisco-logo.svg"),
);

@each $brand, $logo in $product-logos {
  .#{$brand}-link {
    position: relative;
    display: block;

    &:hover .image-hover {
      background-image: $logo;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      display: block;
    }
  }
}

//logo images that are displayed when hovering products
.image-hover {
  display: none;
  position: absolute;
  top: 45%;
  left: 45%;
  transform: translate(-45%, -45%);
  height: 50px;
  width: 250px;
  z-index: 2;
}

.leaves-wrapper {
  position: absolute;
  z-index: -2;
}

.left-leaves {
  @extend .leaves-wrapper;
  bottom: -70%;
  left: 17%;
}

.right-leaves {
  @extend .leaves-wrapper;
  @include rotate(180deg);
  bottom: -45%;
  right: 36%;
}
