section {
  .color-header {
    width: 100vw;
  }

  .header-title {
    padding-top: 20px;
    margin-left: 350px;
  }
}

@include large {
  section {
    .color-header {
      width: 100vw;
      height: 150px;
      margin-left: -350px; //margin for pulling left the details title colored bar
    }
  }
}

@include max-large {
  section {
    .color-header {
      height: 130px;
      margin-left: -30px;
    }

    .header-title {
      padding-top: 20px;
      margin-left: 30px;
    }
  }
}

@include max-extra-small {
  section {
    .color-header {
      height: 100px;
      margin-left: -30px;
    }

    .header-title {
      padding-top: 10px;
      margin-left: 30px;
    }
  }
}
