//NORMAL INPUT -----------------------------------
input {
  height: 40px;
  margin-top: 5px;
  border: 1px solid $grey;
  border-radius: $radius-1;
  color: $dark-blue;

  &:disabled {
    cursor: no-drop;

    + label {
      color: $grey;
    }
  }

  &[type="text"],
  &[type="search"] {
    @extend .placeholder-text;
    padding-left: 10px;

    &:focus {
      border: 1px solid $light-green;
      box-sizing: border-box;
      box-shadow: 0px 0px 4px $light-green;
      border-radius: $radius-1;
      outline: none;
      @include transition(0.1s);
    }

    &:invalid {
      @extend .label-text;
      color: $orange;
      @extend .error-text;
    }
  }
}

.label-textfield {
  @extend .label-text;
  color: $dark-blue;
  margin-bottom: 3px;
}

// TEXTAREA -------------------------------------
textarea {
  margin-top: 5px;
  border: 1px solid $grey;
  border-radius: $radius-1;
  color: $dark-blue;

  &::placeholder {
    @extend .placeholder-text;
  }

  &[type="text"] {
    @extend .placeholder-text;
    padding-left: 10px;
    padding-top: 10px;
  }

  &:focus {
    border: 1px solid $light-green;
    box-shadow: 0px 0px 3px rgba($light-green, 0.8);
    border-radius: $radius-1;
    outline: none;
    @include transition(0.1s);
  }

  &:invalid {
    @extend .label-text;
    color: $orange;
    @extend .error-text;
  }
}

// CHECKBOXES -------------------------------
.check-container {
  @extend .small-text;
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 15px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  //add blue border when hover
  &:hover input ~ .checkmark {
    background-color: $white;
    border: 1px solid $dark-blue;
  }

  //hide default browser checkbox
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    //add blue background when selected
    &:checked ~ .checkmark {
      background-color: $dark-blue;
      border: 1px solid $dark-blue;

      //show check when selected
      &:after {
        display: block;
      }
    }
  }

  //check styling
  .checkmark:after {
    left: 5px;
    width: 5px;
    height: 12px;
    border: solid $white;
    border-radius: 2px;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

//customize check
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: $radius-1;
  background-color: $white;
  border: 1px solid $grey;

  //add check and hide it when it's not selected
  &:after {
    content: "";
    position: absolute;
    display: none;
  }
}

// RADIO BUTTONS -----------------------------------
.radio-container {
  @extend .small-text;
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 15px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    //when it's selected
    &:checked ~ .radio-mark {
      background-color: $dark-blue;
      border: 1px solid $dark-blue;

      //show circle indicator when selected
      &:after {
        display: block;
      }
    }
  }

  &:hover input ~ .radio-mark {
    border: 1px solid $dark-blue;
  }

  &:disabled input ~ .radio-mark {
    color: $grey;
  }

  //circle style
  .radio-mark:after {
    top: 4px;
    left: 4px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: $white;
  }
}

//customize radio button
.radio-mark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: $white;
  border: 1px solid $grey;
  border-radius: 50%;

  //add circle and hide it when it's not selected
  &:after {
    content: "";
    position: absolute;
    display: none;
  }
}
