footer {
  width: 100%;
  background: $white;
  color: $dark-blue;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
  display: block;

  #floryptus-logo {
    margin-left: $global-margin-sides - 20;
    display: flex;
    align-items: center;
    height: 200px;
    width: 300px;
  }

  a {
    @include transition(0.3s);
    display: block;
    color: $dark-blue;
    margin-top: 10px;
    text-decoration: none;

    &:hover {
      @include transition(0.3s);
      color: $light-green;
      margin-left: 5px;
    }
  }

  .footer-content {
    width: 100%;
    padding-bottom: 30px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;

    .footer-section {
      flex: 1;

      p {
        margin-top: 5px;
      }
    }

    .contacts {
      margin-top: 45px;
      margin-left: 50px;
    }

    .links {
      margin-top: 30px;
    }

    .brands {
      margin-top: 30px;
    }

    .qpetisco-logo {
      margin-left: 15px;
    }
  }

  .footer-bottom {
    background-color: rgba($color: $grey, $alpha: 0.5);
    width: 100%;
    height: 50px;
    bottom: 0;
    left: 0;

    p {
      @extend .small-text;
      text-align: center;
      color: $dark-blue;
      padding-top: 18px;
    }
  }
}

@include max-medium {
  .footer {
    width: 100%;
  }

  .footer-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .footer-section {
      padding: 15px 25px;
    }
  }
}

@include extra-small {
  .footer-section {
    padding: 25px;
  }
}
