//BASE BUTTON -------------------------------------------------
.btn {
  @extend .button-text;
  cursor: pointer;
  border: none;
  border-radius: $radius-1;
  padding: 15px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  transition: 0.1s all;
  color: $white;

  &:hover {
    box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.3);
    transform: scale(1.02);
  }

  &:focus {
    border: 2px solid $grey;
    box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.3);
  }

  &:active {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
      inset 0px 5px 15px rgba(0, 0, 0, 0.15);
  }

  &:disabled {
    background-color: $grey;
    box-shadow: none;
    cursor: no-drop;
  }

  //BUTTON STYLES -------------------------------------------------
  //primary button
  &--primary {
    background-color: $light-green;
    color: $white;
  }

  //buttons for floryptus' products
  &--fig {
    background-color: $light-green;
    color: $white;
  }

  &--blueberry {
    background-color: $light-blue;
    color: $white;
  }

  //secondary button
  &--secondary {
    @extend .btn--primary;
    color: $light-green;
    background-color: transparent;
    border: 2px solid $light-green;
    box-shadow: none;

    &:hover {
      border: 3px solid $light-green;
      box-shadow: none;
    }

    &:active {
      border: 3px solid $light-green;
      box-sizing: border-box;
      box-shadow: 0px 0px 5px $light-green;
    }

    &:disabled {
      background-color: transparent;
      color: $grey;
      border: 2px solid $grey;
      box-shadow: none;
      cursor: no-drop;
    }
  }

  //BUTTON SIZES -------------------------------------------------
  &--normal {
    padding: 15px 25px;
  }

  &--medium {
    padding-left: 35px;
    padding-right: 35px;
  }

  &--large {
    padding-left: 45px;
    padding-right: 45px;
  }
}
